// Generated by Framer (89417f6)

import { addFonts, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Arrow from "https://framerusercontent.com/modules/amUBM2bzmV1AZgGGeOOU/Bz9UQAKgfMQMwUGmnZ4r/k7Tpf2jiV.js";
import BuyTemplateButton from "https://framerusercontent.com/modules/Apk06RriukW7D2eEoGlJ/mP5kkuCXmaQLW34qovNJ/DqGFJQyu4.js";
import BuyTemplateButton1 from "https://framerusercontent.com/modules/opQtzXj3LzPCCwZHoiVM/yyokRnlYrp3k5OExFAa6/QtSlkE_ny.js";
const ArrowFonts = getFonts(Arrow);
const BuyTemplateButtonFonts = getFonts(BuyTemplateButton);
const BuyTemplateButton1Fonts = getFonts(BuyTemplateButton1);

const enabledGestures = {Qc_1d9yPF: {hover: true}};

const cycleOrder = ["Qc_1d9yPF"];

const variantClassNames = {Qc_1d9yPF: "framer-v-qutaj0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 615, type: "spring"}};

const transformTemplate = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Qc_1d9yPF", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeargnqhaj = activeVariantCallback(async (...args) => {
setVariant(CycleVariantState)
})

useOnVariantChange(baseVariant, {default: onAppeargnqhaj})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pJMKl", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-qutaj0", className)} data-framer-name={"Remove This Buy Promo"} data-highlight layoutDependency={layoutDependency} layoutId={"Qc_1d9yPF"} ref={ref} style={{...style}} {...addPropertyOverrides({"Qc_1d9yPF-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-clqoog-container"} data-framer-name={"Remove This Arrow"} layoutDependency={layoutDependency} layoutId={"zDzE5hXbA-container"} name={"Remove This Arrow"} transformTemplate={transformTemplate}><Arrow height={"100%"} id={"zDzE5hXbA"} layoutId={"zDzE5hXbA"} name={"Remove This Arrow"} variant={"XHSjoYiUv"} width={"100%"}/></motion.div><motion.div className={"framer-nzcr3b-container"} data-framer-name={"Remove This Buy Button"} layoutDependency={layoutDependency} layoutId={"lUJofm3aY-container"} name={"Remove This Buy Button"} transformTemplate={transformTemplate}><BuyTemplateButton height={"100%"} id={"lUJofm3aY"} layoutId={"lUJofm3aY"} link={"https://finestdevs.com/buy-avanta"} name={"Remove This Buy Button"} title={"Buy This Template for $39"} variant={"Av49agalG"} width={"100%"}/></motion.div><motion.div className={"framer-v3r8mc-container"} data-framer-name={"Remove This Buy Button"} layoutDependency={layoutDependency} layoutId={"PDd4MewNM-container"} name={"Remove This Buy Button"}><BuyTemplateButton1 azjtH6I4y={"rgb(255, 255, 255)"} cduyS1Ada={"https://framerbite.com/all-access"} height={"100%"} id={"PDd4MewNM"} layoutId={"PDd4MewNM"} name={"Remove This Buy Button"} width={"100%"} yoHhge7xS={"Get All Templates for $89"} z1SCMIK__={"rgb(17, 17, 19)"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pJMKl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pJMKl .framer-1591aok { display: block; }", ".framer-pJMKl .framer-qutaj0 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-pJMKl .framer-clqoog-container { bottom: 105px; flex: none; height: auto; left: 89%; position: absolute; width: auto; }", ".framer-pJMKl .framer-nzcr3b-container { bottom: 52px; flex: none; height: auto; left: 49%; position: absolute; width: auto; z-index: 1; }", ".framer-pJMKl .framer-v3r8mc-container { flex: none; height: auto; position: relative; width: auto; z-index: 1; }", ".framer-pJMKl .framer-v-qutaj0 .framer-qutaj0 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pJMKl .framer-qutaj0 { gap: 0px; } .framer-pJMKl .framer-qutaj0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-pJMKl .framer-qutaj0 > :first-child { margin-left: 0px; } .framer-pJMKl .framer-qutaj0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 225
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"iG32xPnMl":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables false
 */
const FrameruJ5LAqxnq: React.ComponentType<Props> = withCSS(Component, css, "framer-pJMKl") as typeof Component;
export default FrameruJ5LAqxnq;

FrameruJ5LAqxnq.displayName = "Button Area";

FrameruJ5LAqxnq.defaultProps = {height: 46, width: 225};

addFonts(FrameruJ5LAqxnq, [...ArrowFonts, ...BuyTemplateButtonFonts, ...BuyTemplateButton1Fonts])